<template>
  <div class="home-body-container">
    <div class="top-body">
      <!-- <img src="@/assets/home-background.png" alt="" /> -->
      <!-- 背景和主按钮 -->
      <div class="introduce-container">
        <div class="introduce-wall">
          <div class="wall-title">新全景新世界</div>
          <div class="wall-video">短片介绍</div>
          <div class="wall-bottom-container">
            <div class="wall-go-to">进入首页</div>
            <router-link class="wall-go-do" to="/my/publish">立马发布</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="middle-container">
      <div class="introduce-bg-container"  >
          <img src="@/assets/introduce-bg.png" alt="">
      </div>
    </div>
    <div class="bottom-container">
        <img src="@/assets/bottom-background.jpg" alt="">
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      introduce_1: require("@/assets/introduce-1.jpg"),
      introduce_2: require("@/assets/introduce-2.jpg"),
    };
  },
};
</script>
<style lang="scss">
.home-body-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .top-body {
    position: relative;
    img {
      width: 100%;
    }
    .introduce-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .introduce-wall {
        width: 500px;
        color: #ffffff;
        .wall-title {
          display: flex;
          justify-content: center;
          font-size: 70px;
          font-weight: 800;
          margin-bottom: 30px;
        }
        .wall-video {
          display: flex;
          justify-content: center;
          font-size: 20px;
          margin-bottom: 50px;
        }
        .wall-bottom-container {
          display: flex;
          justify-content: center;
          .wall-go-to,
          .wall-go-do {
            height: 40px;
            width: 140px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            font-size: 16px;
            font-weight: 600;
            color: #ffffff;
          }
          .wall-go-to {
            margin-right: 5px;
            border: 1px solid #ffffff;
            transition: all 0.3s ease-in-out;
          }
          .wall-go-to:hover {
            cursor: pointer;
            background: #ffffff;
            color: #333333;
          }
          .wall-go-do {
            margin-left: 5px;
            background: #286efa;
            transition: all 0.3s ease-in-out;
          }
          .wall-go-do:hover {
            cursor: pointer;
            background: #5a8df5;
          }
        }
      }
    }
  }
  .middle-container {
    display: flex;
    justify-content: center;
    background: #ffffff;
    .introduce-bg-container {
      width: 70%;
      min-width: 800px;
      img{
          width: 100%;
      }
    }
  }
  .bottom-container{
      img{
          width: 100%;
      }
  }
}
</style>